import { useRef, useEffect, useState } from "react";
import { BrowserMultiFormatReader, NotFoundException } from "@zxing/library";
import { db, doc, getDoc, setDoc } from "@/utility/firebase_options";
import { toast } from "react-toastify";
import { Button } from "antd";
import { ThunderboltFilled, ThunderboltOutlined } from "@ant-design/icons";
const index = () => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFlashOn, setIsFlashOn] = useState(false);
  const toggleFlashlight = async () => {
    if (videoRef.current) {
      const stream = (videoRef.current as HTMLVideoElement)
        .srcObject as MediaStream;
      const tracks = stream.getVideoTracks();
      if (tracks.length > 0) {
        const track = tracks[0];
        const capabilities = track.getCapabilities();
        if ("torch" in capabilities) {
          await track.applyConstraints({
            advanced: [{ torch: !isFlashOn } as any], // Toggle flashlight
          });
          setIsFlashOn(!isFlashOn); // Update state flashlight
        }
      }
    }
  };
  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    const startScanning = () => {
      codeReader.decodeFromVideoDevice(
        null,

        videoRef.current,
        async (result, err) => {
          if (result) {
            setIsLoading(true);
            codeReader.reset(); // Hentikan pembacaan video
            const docRef = doc(db, "tamu", result.toString());
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
              setIsLoading(false);
              toast.warning("Tamu ini tidak terdaftar.", { autoClose: 1000 });
              
              return;
            }
            const data = docSnap.data();
            const tamuRef = doc(db, "tamu", result.toString());
            await setDoc(tamuRef, { hadir: true }, { merge: true });
            setIsLoading(false);
            
            toast.success(`Tamu ${data.displayName} berhasil check in.`, {
              autoClose: 1000,
            });
            
           
            startScanning();
            return;
          }
          if (err && !(err instanceof NotFoundException)) {
            toast.error("Gagal melakukan scan QR Code", { autoClose: 1000 });
          }
        }
      );
    };

    startScanning(); // Mulai pemindaian pertama kali

    return () => {
      codeReader.reset();
    };
  }, []);

  return (
    <div className="wi-w-full wi-h-full wi-fixed wi-top-0 wi-left-0 wi-flex wi-items-center wi-justify-center">
      {isLoading && (
        <div className="wi-absolute wi-flex wi-items-center wi-justify-center wi-bg-black wi-bg-opacity-50 wi-w-full wi-h-full">
          <div className="wi-loader wi-ease-linear wi-rounded-full wi-border-4 wi-border-t-4 wi-border-gray-200 wi-h-[100px] wi-w-[100px] wi-animate-spin">
            <div className="wi-absolute wi-top-0 wi-left-0 wi-w-full wi-h-full wi-flex wi-items-center wi-justify-center">
              <div className="wi-loader-inner wi-rounded-full wi-border-4 wi-border-t-4 wi-border-gray-400 wi-h-[80px] wi-w-[80px] wi-animate-spin-slow"></div>
            </div>
          </div>
        </div>
      )}
      <video ref={videoRef} className="wi-w-full wi-h-full wi-object-cover" />
      {!isLoading && (
        <div className="wi-absolute wi-border-4 wi-border-green-500 wi-border-dashed wi-w-64 wi-h-64 wi-top-1/2 wi-left-1/2 wi-transform wi--translate-x-1/2 wi--translate-y-1/2"></div>
      )}
      <Button
        onClick={toggleFlashlight}
        shape="circle"
        className="wi-absolute !wi-w-[55px] !wi-h-[55px] wi-bottom-4 wi-right-4 wi-bg-white wi-p-2 wi-rounded"
      >
        {isFlashOn ? (
          <ThunderboltFilled className="wi-text-[30px]" />
        ) : (
          <ThunderboltOutlined className="wi-text-[30px]" />
        )}
      </Button>
    </div>
  );
};

export default index;
